<template>
  <v-card v-bind="$attrs">
    <v-form ref="form" v-model="isFormValid" @submit.prevent="handleSubmit">
  <v-card-text>
    <KFieldGroup language-prefix="report.settings.fields">
        <KRadioGroup field="clientDisplayType" v-model="form.clientDisplayType" :items="clientDisplayTypes"/>
        <ClientsAutocomplete field="clients" multiple v-model="form.clients"/>
    </KFieldGroup>
  </v-card-text>
    <v-card-actions>
      <VSpacer/>
      <VBtn
        color="primary"
        type="submit"
        v-text="$t('actions.save')"/>
    </v-card-actions>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import ClientsAutocomplete from '../../components/autocompletes/ClientsAutocomplete.vue';
import KRadioGroup from '../../components/crud/fields/KRadioGroup.vue';
import eventBus from '@/eventBus.js';
import {update, show} from '@/api/endpoints/profile/reportSettings';
import KFieldGroup from '../../components/crud/fields/KFieldGroup.vue';

export default {
  name: 'ReportSettings',
  inheritAttrs: false,
  components: { KFieldGroup, KRadioGroup, ClientsAutocomplete },
  data: () => ({
    isFormValid: false,
    form: {
      clients: [],
      clientDisplayType: '',
    }
  }),
  async created() {
    const response = await show();
    this.form = response.data.data;
  },
  computed: {
    clientDisplayTypes() {
      return [
        {
          text: this.$t('report.settings.clientDisplayType.all_clients'),
          value: 'all_clients',
        },
        {
          text: this.$t('report.settings.clientDisplayType.unassigned_clients'),
          value: 'unassigned_clients',
        },
        {
          text: this.$t('report.settings.clientDisplayType.my_clients'),
          value: 'my_clients',
        },
      ]
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await update(this.form);
        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('global.success:saved'),
        });
      } catch (e) {
        this.$refs.form.validate();
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('global.error'),
        });
      }
    }
  }
};
</script>
