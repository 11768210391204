<template>
  <KPaginatedAutocomplete :field="field" :paginator="autocomplete" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete';
import { autocomplete } from '@/api/endpoints/client/autocomplete';

export default {
  name: 'ClientsAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete(...args) {
      return autocomplete(...args);
    },
  },
  props: {
    field: {
      type: String,
      required: true,
    },
  },
};
</script>
