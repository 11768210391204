<template>
  <v-container>
    <v-card outlined class="mx-auto mb-6" max-width="600">
      <KFieldGroup language-prefix="profile.fields">
      <v-form ref="form" v-model="isFormValid" @submit.prevent="handlePasswordChange()">
        <!-- @TODO: top and footer bar are not top most, thus overlay is on top-->
        <v-overlay :absolute="true" v-if="passwordChangeIsPending">
          <VProgressCircular size="64" :width="6" color="accent" indeterminate/>
        </v-overlay>

        <v-card-title class="pb-2">{{ $t('profile.generalInformation') }}</v-card-title>
        <v-card-text>
          <v-alert
              :max-width="600"
              :type="alert.type || 'info'"
              :value="!!alert.message"
              class="mb-10 mx-auto"
              transition="fade-transition"
          >
            {{ alert.message }}
          </v-alert>
          <v-row dense>
            <v-col>
              <KTextField tabindex="1" v-model="profile.name" field="name" disabled hide-details/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <KTextField tabindex="2" v-model="profile.email" field="email" disabled hide-details/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-title class="pb-2">{{ $t('profile.passwordUpdate.title') }}</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col>
              <KTextField tabindex="3"
                          v-model="form.currentPassword"
                          field="currentPassword"
                          :append-icon="showCurrentPassword ? '$hidden' : '$shown'"
                          @click:append="showCurrentPassword = !showCurrentPassword"
                          :type="showCurrentPassword ? 'text' : 'password'"
                          required/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <KTextField tabindex="4"
                          v-model="form.password"
                          field="password"
                          :append-icon="showPasswords ? '$hidden' : '$shown'"
                          @click:append="showPasswords = !showPasswords"
                          :type="showPasswords ? 'text' : 'password'"
                          :rules="[minimumPasswordLength]"
                          :hint="$t('authorisation.hint.password')"
                          required/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <KTextField tabindex="5"
                          v-model="form.passwordConfirm"
                          field="passwordConfirm"
                          :append-icon="showPasswords ? '$hidden' : '$shown'"
                          @click:append="showPasswords = !showPasswords"
                          :type="showPasswords ? 'text' : 'password'"
                          :rules="[minimumPasswordLength, passwordsMatchValidator]"
                          required/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <VSpacer/>
          <VBtn :disabled="!passwordsMatch || !form.password || !form.passwordConfirm"
                :loading="isLoading"
                color="primary"
                tabindex="5"
                type="submit"
                v-text="$t('actions.save')"/>
        </v-card-actions>
      </v-form>
      </KFieldGroup>
    </v-card>
    <ReportSettings  outlined class="mx-auto" max-width="600"/>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import ProfileModel from '@/application/models/profile';
import { updatePassword } from '@/api/endpoints/profile';
import { getRateLimitMinutes } from '@/api/util/response.js';
import eventBus from '@/eventBus';
import { GET_PROFILE } from '@/store/modules/profile/actionTypes.js';
import ReportSettings from '@/views/profile/ReportSettings.vue';

export default {
  name: 'Profile',
  components: {
    ReportSettings,
    KFieldGroup,
    KTextField,
  },
  data() {
    return {
      form: {
        currentPassword: '',
        password: '',
        passwordConfirm: '',
      },
      showPasswords: false,
      showCurrentPassword: false,
      isFormValid: false,
      isLoading: false,
      alert: {},
    };
  },
  methods: {
    passwordsMatchValidator() {
      return this.passwordsMatch || this.$t('profile.fieldRules.passwordMatch');
    },
    minimumPasswordLength(value) {
      return value.length >= 10 || this.$t('profile.fieldRules.passwordLength');
    },
    async handlePasswordChange() {
      this.$refs.form.validate();
      if (!this.isFormValid) return;

      this.isLoading = true;
      this.setAlert();
      try {
        await updatePassword(this.form.currentPassword, this.form.password, this.form.passwordConfirm);
        this.setAlert('success', this.$t('profile.passwordUpdate.successMessage'));
      } catch (error) {
        const {
          response,
          response: { status },
        } = error;

        if (status === 429) this.setAlert('error', this.$t('errors.429', { minutes: getRateLimitMinutes(response) }));
        if (status === 400) this.setAlert('error', this.$t('profile.passwordUpdate.errorMessage'));
        if (status === 422) this.setAlert('error', this.$t('profile.passwordUpdate.invalidCurrentPassword'));
        this.$refs.form.validate();
      } finally {
        this.isLoading = false;
      }
    },
    setAlert(type = 'info', message = '') {
      this.alert = {
        type,
        message,
      };
    },
  },
  computed: {
    ...mapGetters('profile', { getProfile: GET_PROFILE, passwordChangeIsPending: 'isPending', }),
    ...mapGetters('authorisation', ['getToken']),
    passwordsMatch() {
      return this.form.password === this.form.passwordConfirm;
    },
    profile() {
      return new ProfileModel().mapResponse(this.getProfile);
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'profile' },
        text: this.$tc('profile.title', 1),
      },
    ]);
  },
};
</script>
